<template>
    <div>
        <span class="text">智慧教育</span>
        <el-carousel trigger="click" :autoplay="true" height="650px" indicator-position="outside" class="Top">
        <el-carousel-item :key="1" >
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case31.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：湖北省教育厅(湖北省教育技术装备处)</div>
                    <div class="box-text-text2">工程内容：机房系统工程(空调新风系统、门禁系统、电源防雷系统、弱电系统、电视监控系统、漏水监测报警系统、场地环境监控系统、视频会议室系统、供配电系统、UPS系统、消防系统)</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="2">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case32.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：华中师范大学</div>
                    <div class="box-text-text2">工程内容：建筑智能化弱电系统、数字化校园中心机房系统工程(空调新风系统、门禁系统、电源防雷系统、漏水监测报警系统、场地环境监控系统、配电系统、UPS系统)</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="3">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case33.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：中科院武汉分院</div>
                    <div class="box-text-text2">工程内容：建筑智能化弱电系统、监控报警系统、网络系统集成、网络管理软件</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="4">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case34.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：长江大学</div>
                    <div class="box-text-text2">工程内容：智慧校园网中心机房系统工程(模块化机柜、空调新风系统、门禁系统、电源防重系统、漏水监测报警系统、场地环境监控系统、配电系统、视频会议系统、UPS系统、会议室及音响设备系统)</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="5">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case35.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：华中科技大学</div>
                    <div class="box-text-text2">工程内容：数字化校园机房系统工程( 空调新风系统、门禁系统、电源防雷系统、漏水监测报警系统、场地环境监控系统、配电系统、UPS系统)</div>
                </div>
            </div>
        </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.box{
    width: 100%;
    //height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    &-img{
        width: 95%;
        //height: 80%;
        display: flex;
        justify-content: center;
        position: relative;
        flex-wrap: wrap;
        top: 0rem;
        &-img1 {
            margin-top: 1rem;
            width: 100%;
            //height:100%;
        }
    }
    &-text{
        top: 4rem;
        line-height:1.5rem;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 95%;
        font-size: 18px;
        font-weight: 600;
        position: relative;
        &-text1{
            text-align: start;
            width: 100%;
            height: 30px;
            margin-top: 0rem;
            //display: flex;
            position: relative;
            float: inline-start;
        }
        &-text2{
            //display: flex;
            flex-wrap: wrap;
            width: 100%;
            //height: 50px;
            position: relative;
        }
        &-text3{
            display: flex;
            position: relative;
            width: 7%;
        }
        &-text4{
            display: flex;
            position: relative;
            width: 93%;
        }
        &-text5{
            display: flex;
            position: relative;
            width: 100%;
            justify-content: center;
            font-weight: bold;
        }
        &-text6{
            //display: flex;
            position: relative;
            width: 49%;
            top: 1rem;
        }
    }
}
.text{
    margin-top: 2rem;
    line-height:2.5rem;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
}
.block{
    width: 100%;
}
 .el-carousel__item {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
    border: #475669;
 }
 .el-carousel__item:nth-child(n) {
     background-color: #ffffff;
  }
  .Top{
    margin-top: 1rem;
}
</style>